import AbstractStore from '@common/store/AbstractStore';
import Api from '@common/plugins/Api';

interface StepState {
  maxStep: string|null;
  currentStep: string|null;
  options: OptionsInterface|null;
}

class Step extends AbstractStore<StepState> {
  protected data(): StepState {
    return {
      maxStep: null,
      currentStep: null,
      options: null,
    };
  }

  protected initialize(): void {
    this.initialized = true;
  }

  public initializeData(data: StepState): void {
    this.state.maxStep = data.maxStep;
    this.state.currentStep = data.currentStep;
    this.state.options = data.options;
  }

  public changeCurrentStep(currentStep: string|null): void {
    this.state.currentStep = currentStep;
  }

  public reset(): void {
    this.initializeData({
      maxStep: null,
      currentStep: null,
      options: null,
    });
  }

  public loadOptions(uuid: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      Api.get(`/api/subscription/options/${uuid}`)
        .then((response) => {
          this.state.options = response.data as OptionsInterface;
          resolve(true);
        })
        .catch((error) => reject(error.response.data.errors || {}));
    });
  }

  public async getBicFromIban(bic: string): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      Api.get(`/api/bic-from-iban/${bic}`)
        .then((response) => {
          resolve(response.data.bic as string);
        })
        .catch((error) => reject(error.response.data.errors || {}));
    });
  }

  public get options(): OptionsInterface|null {
    return this.state.options;
  }

  public set options(options: OptionsInterface|null) {
    this.state.options = options;
  }

  public set currentStep(currentStep: string|null) {
    this.state.currentStep = currentStep;
  }

  public set maxStep(maxStep: string|null) {
    this.state.maxStep = maxStep;
  }

  public get optionsYesNo(): SelectOptionInterface[] {
    return [{ value: '1', label: 'Oui' }, { value: '0', label: 'Non' }];
  }

  public setSelectOptions(key: string, selectionOptions: SelectOptionInterface[]): void {
    const { options } = this.state;

    if (options) {
      options.selects[key] = selectionOptions;
    }
  }

  public getSelectOptions(key: string): SelectOptionInterface[] {
    const { options } = this.state;
    if (!options) {
      return this.optionsYesNo;
    }

    const data = (options.selects[key] || null);
    if (!data || !Array.isArray(data)) {
      return this.optionsYesNo;
    }

    return data.map((item) => ({
      value: item.value || item.uuid || item.label || '',
      label: item.label || '',
      description: item.description || '',
      coefficient: item.coefficient || 1,
    }));
  }

  public async getCitiesSelectOptions(key: string): Promise<SelectOptionInterface[]> {
    const dep = key.substr(0, 2);
    if (dep.length !== 2) {
      return [];
    }
    const uri = `https://geo.api.gouv.fr/communes?codeDepartement=${dep}&fields=codesPostaux,codeRegion`;
    const response = await fetch(uri);
    const json = await response.json();

    const cities: SelectOptionInterface[] = [];
    json.forEach((item: {
      code: string;
      codesPostaux: string[];
      nom: string;
      codeRegion: string;
    }) => {
      item.codesPostaux.forEach((value: string) => {
        cities.push({
          value,
          label: item.nom,
          description: item.codeRegion,
          details: item.code,
        });
      });
    });

    return cities;
  }

  public getSelectValue(key: string, value: null|string|number) {
    const options = this.getSelectOptions(key);

    return options.reduce((acc: null|string|number, option) => (
      (option.value || '0').toString() === (value || '0').toString() ? option.label : acc
    ), value);
  }

  public numberFormat(value: number|string|null, suffix = '', digit = 2): string {
    return parseFloat((value || 0).toString())
      .toFixed(digit)
      .replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
      + suffix;
  }

  public get inVerification(): boolean {
    return this.state.currentStep === 'verification';
  }

  public get inInvestments(): boolean {
    return this.state.currentStep === 'investments';
  }

  public get stepsName() {
    return {
      dashboard: 'Contrat',
      subscriber: 'Souscripteur(s)',
      survey: 'Questionnaire',
      patrimony: 'Patrimoine',
      project: 'Projet',
      investments: 'Placements',
      assets: 'Actifs',
      beneficiaries: 'Bénéficiaires',
      payment: 'Règlement',
      'adviser-form': 'Formulaire conseiller',
      arbitration: 'Arbitrage',
      verification: 'Vérification',
      attachments: 'Pièces à fournir',
      'electronic-signature': 'Signature électronique',
    };
  }
}

export default new Step();

import { RouteLocationNormalized } from 'vue-router';

const Edit = () => import(/* webpackChunkName: "subscription" */ '@main/pages/subscription/Edit.vue');
const Beneficiaries = () => import(/* webpackChunkName: "subscription" */ '@main/pages/subscription/Beneficiaries.vue');
const Subscriber = () => import(/* webpackChunkName: "subscription" */ '@main/pages/subscription/Subscriber.vue');
const Survey = () => import(/* webpackChunkName: "subscription" */ '@main/pages/subscription/Survey.vue');
const Patrimony = () => import(/* webpackChunkName: "subscription" */ '@main/pages/subscription/Patrimony.vue');
const Investments = () => import(/* webpackChunkName: "subscription" */ '@main/pages/subscription/Investments.vue');
const Project = () => import(/* webpackChunkName: "subscription" */ '@main/pages/subscription/Project.vue');
const Attachments = () => import(/* webpackChunkName: "subscription" */ '@main/pages/subscription/Attachments.vue');
const Payment = () => import(/* webpackChunkName: "subscription" */ '@main/pages/subscription/Payment.vue');
const Assets = () => import(/* webpackChunkName: "subscription" */ '@main/pages/subscription/Assets.vue');
const AdviserForm = () => import(/* webpackChunkName: "subscription" */ '@main/pages/subscription/AdviserForm.vue');
const Verification = () => import(/* webpackChunkName: "subscription" */ '@main/pages/subscription/Verification.vue');
const ElectronicSignature = () => import(/* webpackChunkName: "subscription" */ '@main/pages/subscription/ElectronicSignature.vue');
const Arbitration = () => import(/* webpackChunkName: "subscription" */ '@main/pages/subscription/Arbitration.vue');

export default [
  {
    path: '/subscription/:uuid',
    component: Edit,
    name: 'subscription',
    props: (route: RouteLocationNormalized) => ({ uuid: route.params && route.params.uuid }),
    children: [
      {
        path: 'subscriber',
        name: 'subscription.subscriber',
        component: Subscriber,
      },
      {
        path: 'survey',
        name: 'subscription.survey',
        component: Survey,
      },
      {
        path: 'patrimony',
        name: 'subscription.patrimony',
        component: Patrimony,
      },
      {
        path: 'project',
        name: 'subscription.project',
        component: Project,
      },
      {
        path: 'investments',
        name: 'subscription.investments',
        component: Investments,
      },
      {
        path: 'assets',
        name: 'subscription.assets',
        component: Assets,
      },
      {
        path: 'beneficiaries',
        name: 'subscription.beneficiaries',
        component: Beneficiaries,
      },
      {
        path: 'payment',
        name: 'subscription.payment',
        component: Payment,
      },
      {
        path: 'adviser-form',
        name: 'subscription.adviser-form',
        component: AdviserForm,
      },
      {
        path: 'verification',
        name: 'subscription.verification',
        component: Verification,
      },
      {
        path: 'attachments',
        name: 'subscription.attachments',
        component: Attachments,
      },
      {
        path: 'electronic-signature',
        name: 'subscription.electronic-signature',
        component: ElectronicSignature,
      },
      {
        path: 'arbitration',
        name: 'subscription.arbitration',
        component: Arbitration,
      },
    ],
  },
];

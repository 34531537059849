import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import User from '@common/store/User';
import Subscription from '@main/router/subscription';

const Dashboard = () => import(/* webpackChunkName: "dashboard" */ '@main/pages/Dashboard.vue');
const Unauthorized = () => import(/* webpackChunkName: "dashboard" */ '@main/pages/Unauthorized.vue');
const PageNotFound = () => import(/* webpackChunkName: "dashboard" */ '@main/pages/PageNotFound.vue');
const Subscriptions = () => import(/* webpackChunkName: "dashboard" */ '@main/pages/dashboard/Subscriptions.vue');
const AdditionalDeposits = () => import(/* webpackChunkName: "dashboard" */ '@main/pages/dashboard/AdditionalDeposits.vue');
const Arbitrations = () => import(/* webpackChunkName: "dashboard" */ '@main/pages/dashboard/Arbitrations.vue');

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: Dashboard,
    redirect: { name: 'subscription.dashboard' },
    children: [
      {
        path: '/additional-deposits',
        component: AdditionalDeposits,
        name: 'additional-deposit.dashboard',
      },
      {
        path: '/arbitrations',
        component: Arbitrations,
        name: 'arbitration.dashboard',
      },
      {
        path: '/subscriptions',
        component: Subscriptions,
        name: 'subscription.dashboard',
      },
    ],
  },
  ...Subscription,
  {
    path: '/unauthorized',
    component: Unauthorized,
    name: 'Unauthorized',
  },
  {
    path: '/:catchAll(.*)',
    name: 'PageNotFound',
    component: PageNotFound,
  },
];

const router = createRouter({
  history: createWebHistory(''),
  routes,
});

router.beforeEach((to, from, next) => {
  if (!User.isAuthorized && to.name !== 'Unauthorized') {
    next({ name: 'Unauthorized' });
    return;
  }

  next();
});

export default router;
